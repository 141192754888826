@if (!responsive) {
  <nav class="app-container flex flex-row items-center">
    <app-logo width="127" height="31" class="contrast mr-4" routerLink="/"></app-logo>

    <div  class="nav-items flex flex-row basis-full items-center">
      @if (showItems.homeIcon) {
        <button mat-icon-button class="mr-2" routerLink="/">
          <mat-icon color="primary">home</mat-icon>
        </button>
      }
      <button mat-button class="mr-2" routerLink="music" routerLinkActive="active">Music</button>
      <button mat-button class="mr-2" routerLink="videos" routerLinkActive="active">Videos</button>
      <button mat-button class="mr-2" routerLink="podcasts">Podcasts</button>
      @if (showItems.resources) {
        <button mat-button routerLink="/resources">Resources</button>
      }
    </div>
    @if (enableSearch) {
      <app-search-field class="mr-2"(executeSearch)="doSearch($event)" size="small"></app-search-field>
    }


    @if (showItems.residentView) {
      <a (click)="backToResidentView()" mat-fab class="ml-2 !w-[42px] !h-[42px] !shadow-none" color="primary">
        <mat-icon>groups</mat-icon>
      </a>
    }
    @if (showItems.backToAdmin) {
      <a (click)="backToAdmin(facilityId); clearFacilityId()" mat-icon-button class="ml-2" color="primary">
        <mat-icon>manage_accounts</mat-icon>
      </a>
    }
    @if (showItems.showLogout) {
      <a (click)="doLogout('')"mat-icon-button class="ml-2" color="primary">
        <mat-icon>logout</mat-icon>
      </a>
    }
  </nav>
} @else {
  <nav class="mobile-app-container flex flex-row justify-between basis-full items-center">
    <button mat-icon-button (click)="doToggleSideMenu()">
      <mat-icon color="primary">menu</mat-icon>
    </button>

    <app-logo width="127" height="31" class="contrast" routerLink="/"></app-logo>

    <div class="basis-full spacer"></div>

    @if (enableSearch) {
      <a (click)="doSearch()" mat-fab class="!w-[42px] !h-[42px] !shadow-none mr-3">
        <mat-icon>search</mat-icon>
      </a>
    }

    @if (showItems.residentView) {
      <a routerLink="/manage/residents/list" mat-fab class="!w-[42px] !h-[42px] !shadow-none mr-3" color="primary">
        <mat-icon>groups</mat-icon>
      </a>
    }
  </nav>
}