<div class="header flex flex-col bgc-neutral-500 text-white py-4 px-6">
  <h2 class="!m-0 flex flex-row items-center">
    <mat-icon class="cursor-pointer" (click)="goBack()">arrow_back_ios</mat-icon>
    {{ content?.heading }}</h2>
</div>
<div class="py-4 px-6">
  <div class="mb-4">{{ content?.body }}</div>
  <div class="grid gap-4 grid-cols-2">
    <button mat-stroked-button class="large !p-4" (click)="selectGroup('SMALL')">
      <div class="font-semibold !text-3xl text-center c-accent-500">2-5</div>
      <div class="text-center">Small Group</div>
    </button>

    <button mat-stroked-button class="large !p-4" (click)="selectGroup('MEDIUM')">
      <div class="font-semibold !text-3xl text-center c-accent-500">5-10</div>
      <div class="text-center">Medium Group</div>
    </button>

    <button mat-stroked-button class="large !p-4" (click)="selectGroup('LARGE')">
      <div class="font-semibold !text-3xl text-center c-accent-500">11-15</div>
      <div class="text-center">Large Group</div>
    </button>

    <button mat-stroked-button class="large !p-4" (click)="selectGroup('MEAL_TIME')">
      <div class="font-semibold !text-3xl text-center c-accent-500">15+</div>
      <div class="text-center">Meal time</div>
    </button>



  </div>
</div>
