import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { SlackService } from './shared/services/slack/slack.service';
import { AuthService } from './modules/auth/services/auth.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { retryWithLocalStorageExponentialBackoff } from './shared/utils/retry';
import { AppStateService } from './shared/services/app-state/app-state.service';
import { of } from 'rxjs';
import { awsConfig } from '../../../../libs/includes/aws-export';

@Injectable()
export class AppErrorHandler implements ErrorHandler {
  constructor(
    private injector: Injector,
    private ngZone: NgZone,
  ) {
  }

  handleError(error: Error) {
    let doRedirect = true;
    // Amplify Auth can throw a string error. This may change in v6 if Amplify
    if (typeof error === 'string') {
      error = {
        name: error,
        message: error,
        stack: 'unavailable',
      }
    }
    console.log(error);



    if (this.errorIsReloadable(error)) {
      return retryWithLocalStorageExponentialBackoff(
        () => window.location.reload(),
        () => this.redirectToErrorPage()
      )
    }

    if (this.errorisVimeoRelatedError(error) || this.errorisYoutubeRelatedError(error)) {
      doRedirect = false;
    }

    if (this.errorIs404(error)) {
      const router = this.injector.get(Router);
      return router.navigate(['/not-found']);
    }

    if (this.enableSlackMessaging()) {
      const message = this.generateSlackMessage(error);
      this.sendErrorToSlack(error, message).subscribe(() => {
        if (doRedirect) {
          this.redirectToErrorPage()
        }
      });
    }
  }

  errorisVimeoRelatedError(error: Error) {
    return error.message?.includes('is not embeddable.') ||
      error.message?.includes(`Cannot read properties of undefined (reading 'indexOf')`);
  }
  errorisYoutubeRelatedError(error: Error) {
    return error.message?.includes('Cannot read properties of null');
  }

  errorIsReloadable(error: Error) {
    if (!error.message) {
      return false;
    }
    const message = error.message.toLowerCase();
    const chunkFailedMessage = /loading chunk [\d]+ failed/;
    if (chunkFailedMessage.test(error.message)) {
      return true
    }

    if (message.includes(`'text/html' is not a valid javascript mime type`)) {
      return true
    }

    if (message.includes('failed to fetch dynamically imported module')) {
      return true
    }
    if (message.includes('network error')) {
      return true
    }

    if (message.includes('an unknown error has occurred.')) {
      return true;
    }

    return false
  }

  errorIs404(error: Error) {
    return error.message?.includes('Request failed with status code 404');
  }

  generateSlackMessage(error: Error) {
    const appState = this.injector.get(AppStateService);
    let authService = this.injector.get(AuthService);
    // check if services are available
    if (!authService || !appState  || !authService.isLoggedIn) {
      return undefined;
    }

    const currentRoute = appState.get<string[]>('currentRoute');
    const intendedRoute = Array.isArray(currentRoute) ? currentRoute.join('/') : 'n/a';
    let message = `[Resparke App: ${authService.authState?.username || 'not logged in'}] \`\`\`${error.message}${error.stack}\`\`\`\n\`\`\`${JSON.stringify(window.location)}\`\`\`\n\'${window.navigator ? window.navigator.userAgent : ''}\` intended route: \`${intendedRoute}\``;
    if (window['apollo' as any]) {
      message = `${message}\n release info: \`\`\`${JSON.stringify(window['apollo' as any])}\`\`\``;
    }
    // if we are having unAuth issues, included the auth details in the error
    if (error.message.toLocaleLowerCase().includes('unauthorized') || error.message.toLocaleLowerCase().includes('jwt')) {
      if (awsConfig?.userPoolWebClientId){
        const lastUser = window.localStorage.getItem(`CognitoIdentityServiceProvider.${awsConfig.userPoolWebClientId}.LastAuthUser`);
        if (lastUser) {
          const refresh = window.localStorage.getItem(`CognitoIdentityServiceProvider.${awsConfig.userPoolWebClientId}.${lastUser}.refreshToken`);
          const access = window.localStorage.getItem(`CognitoIdentityServiceProvider.${awsConfig.userPoolWebClientId}.${lastUser}.accessToken`);
          const signInDetails = window.localStorage.getItem(`CognitoIdentityServiceProvider.${awsConfig.userPoolWebClientId}.${lastUser}.signInDetails`);
          message = `${message}\n Auth info: \`\`\`refresh: ${refresh}\`\`\`\n\`\`\`access: ${access}\`\`\`\n\`\`\`details: ${signInDetails}\`\`\``;
        }
      }
    }
    return message;
  }

  sendErrorToSlack(error: Error, message: string) {
    const slack = this.injector.get(SlackService);
    let authService = this.injector.get(AuthService);

    // check if services are available
    if ( !slack || !authService.isLoggedIn) {
      return of(false);
    }

    const environmentName = environment.name;

    return slack.sendMessage({
      message,
      channel: `breaks-${environmentName}`
    });
  }

  enableSlackMessaging() {
    return environment.local ? !['localhost', environment.local.domain].includes(window.location.hostname) : true
  }

  redirectToErrorPage() {
    // const router = this.injector.get(Router);
    this.ngZone.run(() => {
      // when using navigae we are running into named segment issues and infinite loops when
      // an error occors in Search dialog in named router outlet "v". I am not sure why and how to resolve
      // but usin a hard redirect kills all issues for now
      window.location.href='/error';
      // router.navigate([{ outlets: { v: null } }]);
      // router.navigate([{ outlets: { primary: [ '/error' ] }}])
      // this.ngZone.run(() => router.navigate(['/error'], { relativeTo: active.root}));
    });
  }
}