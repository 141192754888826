<div fxLayout="column" fxLayoutAlign="start stretch" fxFlex>

  <div class="topbar-container" fxLayout="row" fxLayoutAlign="space-between center">
    <app-logo width="127" height="31"></app-logo>
    <button mat-icon-button (click)="toggleSidneNav()" *ngIf="isResponsive">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-list role="list" >
    <mat-list-item role="listitem" *ngFor="let item of navItems" color="primary" [routerLink]="item.path" (click)="toggleSidneNav()">
      <mat-icon matListItemIcon [svgIcon]="item.svgIcon">{{item.icon}}</mat-icon>
      <div matListItemTitle>{{item.title}}</div>
    </mat-list-item>
  </mat-list>

</div>