import { NgClass, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterLink } from '@angular/router';
import { ViewModes } from '../../models/global';


@Component({
  selector: 'app-resident-mode-header',
  standalone: true,
  imports: [MatIconModule, RouterLink, MatButtonModule, NgIf, MatProgressSpinnerModule, NgIf],
  templateUrl: './resident-mode-header.component.html',
  styleUrls: ['./resident-mode-header.component.scss']
})
export class ResidentModeHeaderComponent {
  @Output() exitMode = new EventEmitter<ViewModes>();
  @Output() lockMode = new EventEmitter<boolean>();
  @Input() isMobile: boolean;
  @Input() link: string[];
  @Input() currentResidentName: string;
  @Input() viewMode: ViewModes;
  @Input() allowLockMode: boolean;
  @Input() showExitLockModeSpinner: boolean;

  showExitResidentSpinner: boolean;
  showEnterLockModeSpinner: boolean;

  enterLockMode() {
    this.lockMode.emit(true);
  }

  doExitMode(mode: ViewModes) {
    this.exitMode.emit(mode);
  }
}
