import { inject } from '@angular/core';
import {
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
  ResolveFn,
} from '@angular/router';
import { AppStateService } from 'src/app/shared/services/app-state/app-state.service';
import { of, switchMap } from 'rxjs';
import { ContentService } from 'src/app/shared/services/content/content.service';
import { FacilityService } from 'src/app/shared/services/facility/facility.service';
import { WhoIsListeningService } from 'src/app/modules/who-is-listening/services/who-is-listening.service';

export const whoIsListeningResolver: ResolveFn<any> =
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const content = inject(ContentService);
    const appState = inject(AppStateService);
    const whoIsListening = inject(WhoIsListeningService);
    const facility = inject(FacilityService);

    return facility.getFacility(appState.get<string>('currentFacilityId'))
      .pipe(
        switchMap(() => {
          const allow = whoIsListening.allowWhoIsListening();
          if (allow) {
            return content.getContent([
              'whos-listening-start:heading',
              'whos-listening-start:body',
              'whos-listening-expired:heading',
              'whos-listening-expired:body',
              'whos-listening-select-resident:heading',
              'whos-listening-select-group:heading',
              'whos-listening-select-group:body'
            ])
          } else {
            return of(false)
          }
        })
      )
  };
