import { Component, EventEmitter, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { Dictionary } from 'src/app/shared/models/global';
import { AppStateService } from 'src/app/shared/services/app-state/app-state.service';

@Component({
  selector: 'app-wil-select-group',
  standalone: true,
  imports: [MatButtonModule, MatIconModule],
  templateUrl: './select-group.component.html',
  styleUrl: './select-group.component.scss'
})
export class WILSelectGroupComponent {

  @Output() group = new EventEmitter<'SMALL' | 'MEDIUM' | 'LARGE' | 'MEAL_TIME'>()
  @Output() back = new EventEmitter<boolean>();

  content: Dictionary<string>;
  constructor(
    private appState: AppStateService,
  ) {
    const contentCol = this.appState.contentItemCollection;
    this.content = contentCol['whos-listening-select-group'];
  }

  goBack() {
    this.back.emit(true);
  }


  selectGroup(type: 'SMALL' | 'MEDIUM' | 'LARGE' | 'MEAL_TIME') {
    this.group.emit(type);
  }
}