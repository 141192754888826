<mat-card>
  <mat-card-content>
    <h2 class="flex content-baseline justify-center">
      <span
        style="--mat-icon-color: #009999"
        class="align-middle leading-10 block mr-2"
      >
        <i class="material-icons-outlined" style="color: var(--mat-icon-color)">warning</i>
      </span>

      Resparke cannot be accessed
    </h2>
    <p class="text-center">Resparke is being blocked by your organisation's firewall.</p>
    <p class="text-center">
      To access this content, please contact your IT team and ask them to add
      Resparke to your safelist.
    </p>
    <br />
    <p class="text-lg text-center mt-16">
      Domain to safelist: (*.resparke.com)
    </p>

    <div class="text-center mt-12">
      <button color="primary" mat-flat-button (click)="goBack()">
        Go Back
      </button>
    </div>
  </mat-card-content>
</mat-card>
