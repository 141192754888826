
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { svgIcons } from 'src/assets/images/icons/svg/icons';


/**
 * Register our custom SVG within the Mat-Icon library.
 * Doing it dynamically using a manifest file is currently causing loading issues at one facility
 * due to possible firewall restrictions of svg mime type?
 */
export const registerSvgIcons = (matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) => {

  matIconRegistry
    .addSvgIconLiteral('ap_play', domSanitizer.bypassSecurityTrustHtml(svgIcons.play))
    .addSvgIconLiteral('ap_skip_next', domSanitizer.bypassSecurityTrustHtml(svgIcons.skip_next))
    .addSvgIconLiteral('ap_skip_previous', domSanitizer.bypassSecurityTrustHtml(svgIcons.skip_previous))
    .addSvgIconLiteral('ap_playlist', domSanitizer.bypassSecurityTrustHtml(svgIcons.playlist))
    .addSvgIconLiteral('ap_music', domSanitizer.bypassSecurityTrustHtml(svgIcons.music))
    .addSvgIconLiteral('ap_pause', domSanitizer.bypassSecurityTrustHtml(svgIcons.pause))
    .addSvgIconLiteral('ap_equalizer', domSanitizer.bypassSecurityTrustHtml(svgIcons.equalizer))
    .addSvgIconLiteral('ap_content', domSanitizer.bypassSecurityTrustHtml(svgIcons.content))
}