<div class="flex flex-row items-center ml-4 mr-4">

  <ng-container *ngIf="viewMode === 'RESIDENT_LOCK'">

    <button mat-mini-fab [routerLink]="link" class="mr-4">
      <mat-icon>account_circle</mat-icon>
    </button>
    <div class="resident-name text-ellipsis overflow-hidden cursor-pointer" [routerLink]="link">
      {{ currentResidentName }}
    </div>
    <button mat-mini-fab (click)="doExitMode('RESIDENT_LOCK');" class="mr-3" [disabled]="showExitLockModeSpinner">
      <mat-icon *ngIf="!showExitLockModeSpinner">lock</mat-icon>
      <mat-spinner *ngIf="showExitLockModeSpinner" diameter="16"></mat-spinner>
    </button>

  </ng-container>

  <ng-container *ngIf="viewMode === 'RESIDENT_VIEW'">

    <button mat-mini-fab [routerLink]="link" class="mr-4">
      <mat-icon>account_circle</mat-icon>
    </button>
    <div class="resident-name text-ellipsis overflow-hidden cursor-pointer" [routerLink]="link">
      {{ currentResidentName }}
    </div>

    <button mat-mini-fab (click)="enterLockMode(); showEnterLockModeSpinner = true;" class="mr-3" *ngIf="allowLockMode">
      <mat-icon *ngIf="!showEnterLockModeSpinner">lock_open</mat-icon>
      <mat-spinner *ngIf="showEnterLockModeSpinner" diameter="16"></mat-spinner>
    </button>

    <button mat-mini-fab (click)="doExitMode('RESIDENT_VIEW'); showExitResidentSpinner = true;">
      <mat-icon *ngIf="!showExitResidentSpinner">close</mat-icon>
      <mat-spinner *ngIf="showExitResidentSpinner" diameter="16"></mat-spinner>
    </button>
  </ng-container>
</div>