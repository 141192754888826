import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AppStateService } from 'src/app/shared/services/app-state/app-state.service';
import { CategoryCard } from 'src/app/shared/components/category-card/category-card.component';
import { HomePageService } from '../services/home.service';

@Injectable({
  providedIn: 'root'
})
export class HomeCardsResolver  {
  constructor(
    private homepageService: HomePageService,
    private appState: AppStateService,
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<CategoryCard[]> {
    const cards = this.appState.get<CategoryCard[]>('homepageCards') || [];
    if (!cards.length) {
      return this.homepageService.getHomeCards();
    } else {
      return of(cards);
    }
  }
}
