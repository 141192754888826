import { Component } from '@angular/core';

@Component({
  selector: 'app-missing-mediaerror',
  standalone: true,
  imports: [],
  templateUrl: './missing-media.component.html',
})
export class MissingMediaErrorComponent {

}
