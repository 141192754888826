import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Output, } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { distinctUntilChanged, filter, map, of, tap } from 'rxjs';
import { ManageResidentService, Resident } from 'src/app/modules/manage/services/resident/resident.service';
import { Dictionary, UserTypes } from 'src/app/shared/models/global';
import { AppStateService } from 'src/app/shared/services/app-state/app-state.service';
import { deepCopy } from 'src/app/shared/utils/deepCopy';

interface ResidentRow extends Resident {
  startResidentView?: boolean;
}
@Component({
  selector: 'app-wil-select-resident',
  standalone: true,
  imports: [AsyncPipe, MatListModule, MatIconModule, MatTableModule, ReactiveFormsModule, MatFormFieldModule,
    MatInputModule, MatProgressSpinnerModule, MatButtonModule],
  templateUrl: './select-resident.component.html',
  styleUrl: './select-resident.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WILSelectResidentComponent {
  @Output() resident = new EventEmitter<Resident>();
  @Output() back = new EventEmitter<boolean>();
  residents$ = this.appState.get$<Dictionary<Resident[]>>('residentsCollection').pipe(
    filter(col => !!col),
    map<Dictionary<Resident[]>, ResidentRow[]>(col => col[this.appState.get<string>('currentFacilityId')]),
    distinctUntilChanged(), // stops reloading resident list by other resolver which affects the startRes loader
    tap(residents => {
      const anonymous = { id: 'anonymous', firstName: 'Anonymous', lastName: '', facilityId:'', preferenceId: '', roomNumber:''};
      const addNew = { id: 'add-new', firstName: 'Add Resident', lastName: '', facilityId:'', preferenceId: '', roomNumber:''};
      let extra = [anonymous];
      if (this.appState.currentUser?.permissions.includes(UserTypes.FACILITY_ADMIN)) {
        extra = [addNew, ...extra];
      }
      this.dataSource.data = [...extra, ...deepCopy<Resident[]>(residents)]
    })
  );
  content: Dictionary<string>;

  search = new FormControl();
  displayedColumns = ['icon', 'name'];
  dataSource = new MatTableDataSource<ResidentRow>([]);

  constructor(
    private appState: AppStateService,
    private residentsService: ManageResidentService,
  ) {
    const contentCol = this.appState.contentItemCollection;
    this.content = contentCol['whos-listening-select-resident'];
    this.getResidents().subscribe();

    this.search.valueChanges.subscribe(val => {
      this.dataSource.filter = val;
    });
  }

  handleClick(resident: ResidentRow) {
    resident.startResidentView = true;
    this.resident.emit(resident);
  }

  goBack() {
    this.back.emit(true);
  }

  private getResidents() {
    const facilityId = this.appState.get<string>('currentFacilityId');
    const residentCollection = this.appState.get<Dictionary<Resident[]>>('residentsCollection') || {};
    const residents = residentCollection[facilityId] || [];

    // if length of residents is 1, then user did reload in resident mode and we need to fetch full collection
    if (Array.isArray(residents) && residents.length > 1) {
      return of(residents);
    } else {
      return this.residentsService.getResidents(facilityId);
    }
  }
}