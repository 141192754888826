<div fxLayout="row" fxInlineFlex class="breadcrumbs app-container">
  <div fxLayoutAlign="center center" routerLink="/" class="home">
    <mat-icon>home</mat-icon>
    <div class="arrow-right"></div>
  </div>
  <div
    class="crumb"
    fxLayoutAlign="center center"
    *ngFor="let part of _route; let i = index"
    [routerLink]="path(i)"
    [ngClass]="{
      disabled: i === _route.length-1 && i === routePartCount,
      'level-1': 0 === i,
      'level-2': 1 === i,
      'level-3': 2 === i,
      'level-4': 3 === i
    }">
    <mat-icon *ngIf="i > 0">
      chevron_left
    </mat-icon>
    {{ part | spaceCase | titlecase }}
  </div>
</div>