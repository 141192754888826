import { APOLLO_USER_GROUPS } from '../../models/global';
import { NavItem } from '../collapsable-menu/collapsable-menu.component';

export const navItems: NavItem[] = [
  {
    path: '',
    title: 'Home',
    icon: 'home',
    isExternalLink: false,
  },
  {
    path: 'music',
    title: 'Music',
    svgIcon: 'ap_music',
    isExternalLink: false,
  },
  {
    path: 'videos',
    title: 'Video',
    icon: 'videocam',
    isExternalLink: false,
  },
  {
    path: 'podcasts',
    title: 'Podcasts',
    icon: 'podcasts',
    isExternalLink: false,
  },
  {
    path: 'resources',
    title: 'Resources',
    svgIcon: 'ap_playlist',
    isExternalLink: false,
  },
  {
    path: 'manage/residents',
    title: 'View Residents',
    icon: 'groups',
    isExternalLink: false,
    groups: [APOLLO_USER_GROUPS.SUPER_ADMIN,
    APOLLO_USER_GROUPS.FACILITY_ADMIN, APOLLO_USER_GROUPS.VIEW_RESIDENTS, APOLLO_USER_GROUPS.EDIT_RESIDENTS]
  },
  {
    path: 'auth/do-logout',
    title: 'Logout',
    icon: 'logout', isExternalLink: false,
    groups: [APOLLO_USER_GROUPS.SUPER_ADMIN,
      APOLLO_USER_GROUPS.FACILITY_ADMIN]
  }
]