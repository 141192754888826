import { AsyncPipe, NgClass, NgIf, NgTemplateOutlet } from '@angular/common';
import { Component } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet, Event, NavigationStart } from '@angular/router';
import { distinctUntilChanged, filter, of, startWith, Subject, switchMap, takeUntil, tap } from 'rxjs';
import { AppStateService } from 'src/app/shared/services/app-state/app-state.service';
import { UserPermissions, UserTypes, ViewModes } from '../../models/global';
import { BreadCrumbComponent } from '../bread-crumb/bread-crumb.component';
import { NavBarComponent } from '../nav-bar/nav-bar.component';
import { MatDrawerMode, MatSidenavModule } from '@angular/material/sidenav';
import { SideNavComponent } from '../side-nav/side-nav.component';
import { LayoutService } from 'src/app/shared/services/layout/layout.service';
import { AuthService } from 'src/app/modules/auth/services/auth.service';
import { ResidentModeHeaderComponent } from '../resident-mode-header/resident-mode-header.component';
import { UnlockResidentDialogComponent } from '../dialogs/unlock-resident-dialog/unlock-resident-dialog.component';
import { DialogService } from '../../services/dialog/dialog.service';
import { Resident, ManageResidentService } from 'src/app/modules/manage/services/resident/resident.service';
import { WILSessionComponent } from 'src/app/modules/who-is-listening/containers/session/session.component';
import { WhoIsListeningService } from 'src/app/modules/who-is-listening/services/who-is-listening.service';

export type BreadcrumbKind = 'music' | 'videos' | 'other';

export interface Layout {
  openSideNav: boolean;
  opened: boolean;
  mode: MatDrawerMode;
  fixedViewPort: boolean;
  fixedTopGap: number;
  breadCrumbs: boolean;
  breadCrumbKind?: BreadcrumbKind;
  responsive: boolean;
}

const defaultLayout: Layout = {
  openSideNav: true,
  opened: false,
  mode: 'over',
  fixedViewPort: true,
  fixedTopGap: 64,
  breadCrumbs: false,
  responsive: false,
};

interface ShowNavItems {
  showLogout: boolean;
  backToAdmin: boolean;
  residentView: boolean;
  homeIcon: boolean;
  resources: boolean;
}

@Component({
  selector: 'app-authenticated-layout',
  standalone: true,
  imports: [RouterOutlet, NavBarComponent, BreadCrumbComponent, NgIf, AsyncPipe, MatDialogModule, AsyncPipe,
    MatSidenavModule, SideNavComponent, NgClass, NgTemplateOutlet, ResidentModeHeaderComponent, WILSessionComponent],
  templateUrl: './authenticated-layout.component.html',
  styleUrls: ['./authenticated-layout.component.scss'],
})
export class AuthenticatedLayoutComponent {
  layout: Layout = defaultLayout;

  // isMobile$ = this.appState.isMobile$;
  isAdmin = this.appState.currentUser.permissions.includes(UserTypes.FACILITY_ADMIN);
  onDestroy = new Subject();
  isMobile = false;
  allowLockMode: boolean;

  enableWhoIsListening = this.whoIsListening.allowWhoIsListening();
  toggleWhoIsListeningDrawer$ = this.whoIsListening.startSessionSelection$.pipe(distinctUntilChanged());


  showNavItems: ShowNavItems;
  cognitoIdToken: string;

  viewMode$ = this.appState.get$<ViewModes>('viewMode').pipe(
    takeUntil(this.authService.onLogout), // on full logout this observable can be killed because authenticated layout gets recreated on login
    filter(mode => !!mode && mode !== ViewModes.LOGGED_OUT),
    tap((mode) => {
      console.log('>> mode', mode);
      // note: currentResident can be undefined if mode is set before router navigation happened (and resolve of resident)
      const resident = this.appState.get<Resident>('currentResident');
      if (resident) {
        this.allowLockMode = this.appState.allowTempLock() && !resident.locked;
      }
      // Note: I have seen a `Cannot read properties of undefined (reading 'permissions')` error happen on a non authenticated session, hence optional currentuser
      this.showNavItems = {
        showLogout: this.appState.currentUser?.permissions.includes(UserTypes.FACILITY_ADMIN) ||
          this.appState.currentUser?.permissions.includes(UserTypes.SUPER_ADMIN),
        backToAdmin: this.appState.currentUser?.permissions.includes(UserTypes.SUPER_ADMIN),
        residentView: this.appState.currentUser?.permissions.includes(UserPermissions.VIEW_RESIDENTS) ||
          this.appState.currentUser?.permissions.includes(UserPermissions.EDIT_RESIDENTS) ||
          this.appState.currentUser?.permissions.includes(UserTypes.FACILITY_ADMIN),
        homeIcon: true,
        resources: this.appState.get<ViewModes>('viewMode') !== ViewModes.RESIDENT_LOCK,
      };
    })
  );
  showExitLockModeSpinner = false;

  currentResidentName: string;
  currentResidentId: string;
  currentRoute: string[];
  facilityId = this.appState.get<string>('currentFacilityId');

  constructor(
    private router: Router,
    private active: ActivatedRoute,
    private appState: AppStateService,
    private layoutService: LayoutService,
    private dialogService: DialogService,
    private authService: AuthService,
    private residentService: ManageResidentService,
    private whoIsListening: WhoIsListeningService,
  ) {

    this.appState.get$<ViewModes>('viewMode')
      .pipe(takeUntil(this.onDestroy))
      .subscribe(mode => {
        if ([
          ViewModes.RESIDENT_LOCK,
          ViewModes.RESIDENT_VIEW].includes(mode)
        ) {
          // Loading current resident in a non blocking way
          this.loadCurrentResident();
        }
      })
    this.initBreakpointMonitoring();
  }

  ngOnDestroy() {
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }

  // note: Maybe this should be handled in a service on a higher level so the WIL service can also call this function when it times out its session
  // perhaps Appstate is the right location?
  exitViewMode(mode: ViewModes) {
    console.log('exit view mode', mode);
    if (mode === ViewModes.RESIDENT_VIEW) {
      const commands = this.whoIsListening.allowWhoIsListening() ?
        ['/'] : [UserTypes.FACILITY_USER, UserTypes.FACILITY_ADMIN].includes(this.appState.currentUser.userType) ?
          ['manage', 'residents', 'list'] : ['/']

      this.router.navigate(commands, { replaceUrl: true }).finally(() => {
        this.appState.exitMode(ViewModes.RESIDENT_VIEW);
      });
    } else if (mode === ViewModes.RESIDENT_LOCK) {
      this.showExitLockModeSpinner = true;
      this.exitLockMode().subscribe(); // self terminating observable
    } else if (mode === ViewModes.GROUP) {
      this.appState.exitMode(ViewModes.GROUP);
      this.router.navigate(['/'], { replaceUrl: true });
    } else if (mode === ViewModes.ADMIN_MODE) {
      this.appState.exitMode(ViewModes.ADMIN_MODE);
      const command = this.whoIsListening.allowWhoIsListening() ? ['/'] : ['manage/residents/list'];
      this.router.navigate(command, { replaceUrl: true });
    }
  }

  enterLockMode() {
    const residentId = this.appState.get<Resident>('currentResident').id;
    this.residentService.lockResident(residentId)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {

        let path = ['/resident', residentId, 'profile'];
        if (this.appState.allowFavourites()) {
          path = ['/resident', residentId, 'favourites'];
        } else if (this.appState.allowSuggestedContent()) {
          path = ['/resident', residentId, 'content'];
        }
        this.router.navigate(path, { relativeTo: this.active })
          // this lockmode command should be the one being used, not the one in auth.setAuthState.
          // but we also need that one for reload. Figure out a better way
          .finally(() => this.appState.startMode(ViewModes.RESIDENT_LOCK, residentId));
      });
  }

  doLogout() {
    this.authService.logout().subscribe(() => {
      this.router.navigate(['/auth/logout/securely'])
    });
  }

  doSearch(query: string) {
    if (document.location.pathname.search('v:search') === -1) {
      this.router.navigate(['', { outlets: { v: ['search'] } }], { queryParams: { query } });
    }
  }

  private exitLockMode() {
    /**
     * The following happens in order:
     * 1) Open unlock dialog which returns a `dialogRef` Observable
     * 2) Listen for any codeVerified event emitted from dialogRef instance
     * 3) return afterClosed Observable to listen to dialog close
     * 4) if verified, run `doUnlock` function
     * 5) Do navigate and run dialog close (we want the dialog to close on navigation complete)
     * 5) cleanup
     */
    return this.dialogService.showDialog<UnlockResidentDialogComponent>(
      UnlockResidentDialogComponent,
      ['unlock-resident:heading', 'unlock-resident:body'], {
      panelClass: 'default-dark-dialog',
      backdropClass: 'apollo-backdrop',
      data: {
        requireCode: true,
      }
    })
      .pipe(
        switchMap(dialogRef => {
          // async process to execute unlock
          let codeSubscription = dialogRef.componentInstance.codeVerified
            .pipe(
              takeUntil(this.onDestroy), // Make sure we don't leave a memory leak in case unsubscribe doesn't work
              switchMap(result => result ? this.doUnlock() : of(result))
            )
            .subscribe(() => {
              codeSubscription.unsubscribe();
              codeSubscription = undefined;

              this.router.navigate(['/']).finally(() => {
                dialogRef.close();
                this.appState.exitMode(ViewModes.RESIDENT_LOCK);
              });

            });
          return dialogRef.afterClosed()
            .pipe(
              tap(closeAction => {
                // if closeAction is undefined, the backdrop was clicked
                if (!closeAction || closeAction === undefined) {
                  this.showExitLockModeSpinner = false;
                }
              })
            )
        })
      )

  }

  private doUnlock() {
    const residentId = this.appState.get<Resident>('currentResident').id;
    this.appState.setState<ViewModes>('viewMode', ViewModes.EXIT_LOCK_MODE);
    return this.residentService.unlockResident(residentId, true);
  }


  private initBreakpointMonitoring() {
    // NOTE: Consider if this is still the best thing to do, since 2 observables can happen and trigger changes
    // and we now have to use takeUntil to prevent errors.
    this.layoutService.breakpoint$
      .pipe(
        takeUntil(this.onDestroy),
        takeUntil(this.authService.onLogout),
        switchMap(deviceClass => {
          return this.router.events
            .pipe(
              takeUntil(this.onDestroy),
              // this takeUntil is vital to kill this observable on logout.
              takeUntil(this.authService.onLogout),
              // we only want one NavigationEnd and Start event to filter through
              filter((event: Event) => event instanceof NavigationEnd || event instanceof NavigationStart),
              tap(event => {
                if (event instanceof NavigationStart) {
                  this.appState.set<string[]>('currentRoute', cleanUrl(event.url));
                }
              }),
              // we only want one NavigationEnd  event to filter through
              filter((event: Event) => event instanceof NavigationEnd),
              // on first page load the ActivationStart is not triggered, so we start with the correct snapshot
              startWith(this.active.children[0]),
              tap(data => {
                // refresh facilityId
                this.facilityId = this.appState.currentFacilityId;
                this.isMobile = deviceClass['is-xSmall'];
                this.currentRoute = cleanUrl(this.router.url);

                // protect routes from ADMIN mode when WHO_IS_LISTENING is enabled
                const testRoute = this.currentRoute.join('/');
                if (this.appState.get<ViewModes>('viewMode') === ViewModes.ADMIN_MODE &&
                  (testRoute.search(/^(music|video|podcasts)/) !== -1 || testRoute === '')
                ) {
                  this.exitViewMode(ViewModes.ADMIN_MODE);
                }

                this.appState.setState('currentRoute', this.currentRoute);
                this.layout.breadCrumbKind = this.currentRoute[0] === 'music' ? 'music' :
                  this.currentRoute[0] === 'videos' ? 'videos' : 'other';

                if (this.active.children[0].snapshot) {
                  this.layout = {
                    ...this.layout,
                    breadCrumbs: this.active.children[0].snapshot.data.breadcrumbs && !deviceClass['is-xSmall'],
                    responsive: deviceClass['is-xSmall'] || deviceClass['is-small']
                  };
                }
                const scrollWrapper = document.querySelector('.scroll-wrapper');
                if (scrollWrapper) {
                  scrollWrapper.scrollTop = 0;
                }
              }),
            );
        })
      ).subscribe();

    function cleanUrl(url: string) {
      return url
        .replace(/^\//, '') // strip double forward slash
        .replace(/\?.*/, '') // strip queryparams
        .split('/');
    }
  }

  private loadCurrentResident() {
    this.appState.get$<Resident>('currentResident').pipe(
      takeUntil(this.onDestroy),
      filter(val => !!val),
    ).subscribe((resident) => {
      this.currentResidentName = `${resident.firstName}
        ${resident.lastName}
        ${resident.preferredName ? `(${resident.preferredName})` : ''}
      `;
      this.currentResidentId = resident.id;
    });
  }
}

