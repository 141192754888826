import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/modules/auth/services/auth.service';
import { Dictionary, ViewModes } from 'src/app/shared/models/global';
import { AppStateService } from 'src/app/shared/services/app-state/app-state.service';


@Injectable({
  providedIn: 'root'
})
export class TokenLoginGuard {
  constructor(
    private authService: AuthService,
    private appState: AppStateService,
    private router: Router,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    // start in guest mode which removes mode sessionStorage values
    this.appState.startMode(ViewModes.GUEST);

    if (state.url.search(/^\/auth\/.*/) !== -1) {
      return true;
    }
    const { token } = route.queryParams;

    if (token) {
      return this.authService.programmaticLogout().pipe(
        switchMap(val => this.authService.verifyToken({ token })
          .pipe(
            switchMap(result => {
              if (result.payload) {

                const payload = JSON.parse(result.payload) as { username: string, token: string, target: string, payload?: Dictionary<any> };
                const removeChallenge = payload.target !== 'DEFAULT_USER_ACCESS';

                return this.authService.customChallengeLogin({ challenge: payload.token, username: payload.username, removeChallenge })
                  .pipe(
                    map(result => {
                      if (result.status === 'SUCCESS') {
                        const { target } = payload;

                        switch (target) {
                          case 'FACILITY_ADMIN_ONBOARDING':
                            return this.router.parseUrl(`/auth/onboarding?token=${token}`);

                          case 'DEFAULT_USER_ACCESS':
                            return this.router.parseUrl('/');

                          case 'RESIDENT_APP_ACCESS':
                            if (!payload?.payload?.residentId) {
                              return this.router.parseUrl('/auth/expired');
                            }
                            const residentId = payload.payload.residentId;
                            this.appState.set('currentFacilityId', payload.payload.facilityId)
                            this.appState.startMode(ViewModes.RESIDENT_VIEW, residentId);
                            this.authService.disableToken({ token }).subscribe();
                            return this.router.parseUrl(`/resident/${residentId}/profile`);

                          default: {
                            // Default redirect to onboarding because we have tokens not using the target attribute
                            // All new customAuth tokens should use target attribute so this default will not be required anymore
                            // to be removed in December 2023!
                            return this.router.parseUrl(`/auth/onboarding?token=${token}`);
                          }
                        }
                      }
                      return this.router.parseUrl('/auth/expired');
                    })
                  )
              }
              return of(this.router.parseUrl('/auth/expired'))
            })
          )
        ))
    } else {
      return this.router.parseUrl('/auth/login');
    }
  }
}