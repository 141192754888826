import { Injectable } from '@angular/core';
import { AppStateService } from 'src/app/shared/services/app-state/app-state.service';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../auth/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ResourcesGuard {
  resourcesDomain = environment.resourcesDomain

  constructor(
    private appState: AppStateService,
    private authService: AuthService,
  ) { }

  canActivate() {
    this.authService.getCurrentUser().subscribe(() => {
      const cognitoIdToken = this.appState.currentUser.cognitoIdToken;
      if (cognitoIdToken) {
        window.location.href = this.getResourcesLink(cognitoIdToken);
      }
    });
  }

  getResourcesLink(cognitoIdToken: string) {
    const queryParams = decodeURIComponent(window.location.search.replace(/^\?/, ''));
    return `${this.resourcesDomain}/wp-json/cognito-auth/v1/token?token=${cognitoIdToken}${ queryParams ? '&' + queryParams : ''}`
  }
}
