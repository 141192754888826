import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { AppStateService } from '../../../../shared/services/app-state/app-state.service';
import { Dictionary } from '../../../../shared/models/global';
import { WhoIsListeningService } from '../../services/who-is-listening.service';

@Component({
  selector: 'app-wil-select-session',
  standalone: true,
  imports: [MatIconModule, MatButtonModule],
  templateUrl: './select-session.component.html',
  styleUrl: './select-session.component.scss'
})
export class WILSelectSessionComponent {

  @Output() session = new EventEmitter<'RESIDENT' | 'GROUP' | 'ADMIN'>()
  content: Dictionary<string>;
  constructor(
    private appState: AppStateService,
    private wilService: WhoIsListeningService,
  ) {
    const contentCol = this.appState.contentItemCollection;
    if (!this.wilService.sessionExpired) {
      this.content = contentCol['whos-listening-start'];
    } else {
      this.content = contentCol['whos-listening-expired'];
    }
  }

  selectSession(type: 'RESIDENT' | 'GROUP' | 'ADMIN') {
    this.session.emit(type);
  }
}
